import React from "react"
import { navigate } from "gatsby-link"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Radio from "@material-ui/core/Radio"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Datetime from "react-datetime"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"

import workStyle from "assets/jss/material-kit-react/views/sponsorshipPageSections/workStyle.jsx"
import { Checkbox } from "@material-ui/core"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class PreRegistrationSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      experience: "National",
      dob: "",
      membership_option: "Junior",
      photo: false,
      covid: false,
      phone: "",
      emergencyEm: "",
      emergencyNum: "",
      emergNm: "",
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  render() {
    const { classes } = this.props
    return (
      <div
        className={classes.section}
        style={{ backgroundColor: "white", padding: "0.25rem" }}
      >
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Registration for 2021 Season</h2>
            <h4 className={classes.description}>
              Registration for membership for the 2021 season is now open!
            </h4>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.textCenter}
              ></GridItem>
            </GridContainer>
            <form
              name="registration"
              method="post"
              data-netlify="true"
              action="/success"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="bot" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "name",
                      onChange: e => this.setState({ name: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "email",
                      value: this.state.email,
                      onChange: e => this.setState({ email: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Phone Number"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "phone",
                      value: this.state.phone,
                      onChange: e => this.setState({ phone: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Emergency Contact Name"
                    id="emergNm"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "emergNm",
                      value: this.state.emergNm,
                      onChange: e => this.setState({ emergNm: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Emergency Contact Number"
                    id="emergencyNum"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "emergencyNum",
                      value: this.state.emergencyNum,
                      onChange: e =>
                        this.setState({ emergencyNum: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Emergency Contact Email"
                    id="emergencyEm"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "emergencyEm",
                      value: this.state.emergencyEm,
                      onChange: e =>
                        this.setState({ emergencyEm: e.target.value }),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Highest Level Played At
                  </InputLabel>
                  <input
                    style={{ display: "none" }}
                    value={this.state.experience}
                    name="experience"
                  />
                  <CustomDropdown
                    id="experience"
                    buttonText={this.state.experience}
                    hoverColor="info"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="experience"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                      width: "100%",
                      name: "experience",
                    }}
                    onClick={e => this.setState({ experience: e })}
                    dropdownList={[
                      "None",
                      "School",
                      "Recreational",
                      "Regional",
                      "National",
                      "International"
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Membership Option
                  </InputLabel>
                  <input
                    style={{ display: "none" }}
                    value={this.state.membership_option}
                    name="membership_option"
                  />
                  <CustomDropdown
                    id="membership_option"
                    buttonText={this.state.membership_option}
                    hoverColor="info"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="membership_option"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                      width: "100%",
                      name: "membership_option",
                    }}
                    onClick={e => this.setState({ membership_option: e })}
                    dropdownList={[
                      "Junior 12-18",
                      "Mini Sand Spikers 5-12",
                      "Senior 18+",
                      "Associate",
                      "Senior Pay-As-You-Go",
                      "Coach"
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Player Date Of Birth
                  </InputLabel>
                  <input
                    style={{ display: "none" }}
                    value={this.state.dob}
                    name="dob"
                  />
                  <FormControl fullWidth>
                    <Datetime
                      inputProps={{ placeholder: "MM/DD/YYYY" }}
                      timeFormat={false}
                      viewMode={"years"}
                      viewDate={"01/01/1993"}
                      onChange={e => this.setState({ dob: e._d })}
                      name={"dob"}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}></GridItem>
                <br/>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    I give my consent for photographs of me at club activities
                    to be used on the clubs social media and websites. <br />
                    Note, further parental/guardian consent will be sought for
                    Junior members.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.photo}
                          onChange={() =>
                            this.setState({ photo: !this.state.photo })
                          }
                          value={this.state.photo}
                          name="photo"
                          aria-label="Photo Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I consent"
                    />
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    I confirm that I will follow the clubs COVID-19 safety
                    precautions at all times during club activites. This is
                    evolving as the situation changes, but updates to this will
                    be communicated to all members. We may use this information
                    provided in this form to contact you regarding track and
                    trace.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.covid}
                          onChange={() =>
                            this.setState({ covid: !this.state.covid })
                          }
                          value={this.state.covid}
                          name="covid"
                          aria-label="Covid Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I agree to these terms"
                    />
                  </GridItem>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.textCenter}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      !(
                        this.state.name &&
                        this.state.email &&
                        this.state.dob &&
                        this.state.experience &&
                        this.state.phone &&
                        this.state.emergencyEm &&
                        this.state.emergencyNum &&
                        this.state.emergNm &&
                        this.state.membership_option &&
                        this.state.covid
                      )
                    }
                  >
                    Submit Registration
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(workStyle)(PreRegistrationSection)
